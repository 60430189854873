import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import copy from 'copy-to-clipboard'
import React from 'react'
import toastr from 'toastr'

export default function Modal({children, visible, title, desc, type, value, onClose, className}) {
    const copyValue = () => {
        copy(value)
        toastr.success('Link copiado!')
    }

    if (visible !== false) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = 'auto'
    }

    return (
        <div className={`modal ${className ?? ''}`.trim()} style={{display: visible === false && 'none'}}>
            <div className="modal-card">
                <div className="modal-header">
                    <div className="d-flex-column">
                        <h2>{title}</h2>
                        {desc && <span className="modal-desc">{desc}</span>}
                    </div>
                    {onClose && <button className="btn btn-modal-close" onClick={e => onClose(e)}><FontAwesomeIcon icon={faTimes}/></button>}
                </div>
                <div className="modal-card-body">
                    {children ?
                        children
                        :
                        type === 'copy' &&
                            (
                                <div className="modal-type-copy">
                                    <input type="text" disabled value={value}/>
                                    <button className="btn btn-primary" onClick={copyValue}><FontAwesomeIcon icon={faCopy}/>Copiar</button>
                                </div>
                            )

                    }
                </div>
            </div>
        </div>
    )
}
