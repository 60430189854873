import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'

export default function QuemSomos() {
    return (<>
            <Header/>
            <main style={{maxWidth: '700px', margin: 'auto'}}>
                <h1 className="roboto-light">Quem Somos?</h1>
                <p>Cadastro nacional positivo de veterinários recomendamos por criadores de cães de raça pura.</p>

                <h2 className="roboto-light">PROJETO</h2>
                <p>Esse projeto surgiu por iniciativa de criadores responsáveis de cães de raça pura, reunidos e atuantes no grupo POLÍTICA ANIMAL e depois da elaboração inicial, firmou parceria com a empresa SISTEMAPET atuante no mercado Pet brasileiro, para desenvolver e disponibilizar de forma online e gratuita um sistema para o cadastramento de dados e utilizado na forma de consultas periódicas, por todos os criadores, proprietários de animais atuais e futuros e veterinários por todo o Brasil.</p>

                <h2 className="roboto-light">OBJETIVO</h2>
                <p>Catalogar num cadastro único brasileiro, veterinários que atendem criadores de cães de raça pura e que suas práticas são referendadas por esses criadores, como éticas, corretas e com respeito aos criadores responsáveis e suas criações de cães, sem distinção ou ressalvas por serem cães de raça pura criados e/ou comercializados, conforme preceitua a legislação brasileira em vigor no nosso pais.</p>

                <h2 className="roboto-light">FINALIDADE</h2>
                <p>Ter e disponibilizar de forma prática para criadores e seus clientes, um banco de dados com informações comerciais e de contato de veterinários, que apreciam todas as raças de animais, que atendem sem distinção de ideologias políticas, comercias e econômicas, considerando que a criação responsável no Brasil é uma prática legal e também necessária para a preservação das raças de várias espécies.</p>
            </main>
            <Footer/>
        </>
    )
}
