import { faPhone, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PulseLoader } from 'react-spinners'
import Header from '../../components/Header'
import useAuth from '../../contexts/useAuth'
import api from '../../services/api'
import Footer from '../../components/Footer'
import VetCard from '../../components/VetCard'
import Modal from '../../components/Modal'
import { formatPhone, formatURL, validURL } from '../../util'
import { faFacebookSquare, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

export default function Veterinarios() {

    const [vet, setVet] = useState([])
    const [vetModal, setVetModal] = useState({
        visible: false
    })
    const [loading, setLoading] = useState(false)
    const { user, loadingUser } = useAuth()

    const estadoRef = useRef()
    const cidadeRef = useRef()
    const bairroRef = useRef()

    const getVeterinarios = useCallback(
        async () => {
            setLoading(true)
            try {
                const data = {}

                if (estadoRef.current && estadoRef.current.value) data.estado = estadoRef.current.value;
                if (cidadeRef.current && cidadeRef.current.value) data.cidade = cidadeRef.current.value;
                if (bairroRef.current && bairroRef.current.value) data.bairro = bairroRef.current.value;

                const res = await api.get('/', { params: data })

                setVet(res.data && Array.isArray(res.data) ? res.data : [])
            } catch (error) {
                console.error(error)
            }
            setLoading(false)
        },
        [],
    )

    useEffect(() => {
        if (!loadingUser) getVeterinarios();
    }, [
        getVeterinarios,
        loadingUser
    ])

    if (loadingUser) return <>
        <Header />
        <main className="d-flex flex-center">
            <PulseLoader color="#65AAFF" />
        </main>
        <Footer />
    </>;

    const showVetModal = (vet) => {
        if (window.innerWidth <= 500) {
            setVetModal({
                title: vet.Nome,
                desc: vet.Criador ? `Indicado por: ${vet.Criador}` : '',
                children: (<div className="d-flex-column no-decoration">
                    <p className="roboto-light" style={{ maxWidth: '300px' }}>{vet.Descricao}</p>
                    <div className="d-flex-column" style={{ marginBottom: '30px' }}>
                        <span>{vet.Cidade}</span>
                        <span>{vet.Bairro}</span>
                        <span>{vet.Logradouro}, {vet.Numero}</span>
                        <a title="Abrir no Google Maps." target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/${encodeURIComponent(`${vet.Cidade} ${vet.Estado} - ${vet.Logradouro}, ${vet.Numero}`)}`}>Abrir no Google Maps</a>
                    </div>
                    <div className="d-flex-column" style={{ marginBottom: '30px' }}>
                        {vet.Telefone && <span style={{ marginBottom: '10px' }}><FontAwesomeIcon icon={faPhone} /> {formatPhone(vet.Telefone)}</span>}
                        {vet.Whatsapp && <a target="_blank" rel="noreferrer" title="Ir para o WhatsApp." href={`https://api.whatsapp.com/send?phone=55${vet.Whatsapp}`}><span><FontAwesomeIcon icon={faWhatsapp} /> {formatPhone(vet.Whatsapp)}</span></a>}
                    </div>
                    <div>
                        <span style={{ fontSize: '24px' }}>
                            {vet.Facebook && (<a className="mr-15px" target="_blank" rel="noreferrer" href={validURL(vet.Facebook) ? formatURL(vet.Facebook) : `https://facebook.com/${vet.Facebook}`} title="Facebook"><FontAwesomeIcon icon={faFacebookSquare} /></a>)}
                            {vet.Instagram && (<a target="_blank" rel="noreferrer" href={validURL(vet.Instagram) ? formatURL(vet.Instagram) : `https://instagram.com/${vet.Instagram.replace('@', '')}`} title="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>)}
                        </span>
                        {vet.Site && <a className="float-right" target="_blank" rel="noreferrer" title="Ir para o Site." href={formatURL(vet.Site, false)}>{vet.Site}</a>}
                    </div>
                </div>)
            })
        }
    }

    const hideVetModal = () => {
        setVetModal({ visible: false })
    }

    const htmlData = vet.map(veterinario => <VetCard key={veterinario.Id} onClick={() => showVetModal(veterinario)} vet={veterinario} />)

    const resetForm = () => {
        estadoRef.current.selectedIndex = 0
        cidadeRef.current.value = ''
        bairroRef.current.value = ''
    }

    return (
        <>
            <Header />
            <main>
                <Modal onClose={hideVetModal} {...vetModal} />
                <form className="card filter" onSubmit={e => { e.preventDefault(); getVeterinarios(); }}>
                    <div className="input-group">
                        <label htmlFor="estado">Estado:</label>
                        <select className="input-outline" id="estado" ref={estadoRef} name="estado" required defaultValue={user?.Estado}>
                            <option value="">Selecione um estado</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label htmlFor="cidade">Cidade:</label>
                        <input className="input-outline" ref={cidadeRef} type="text" name="cidade" id="cidade" defaultValue={user?.Cidade} />
                    </div>
                    <div className="input-group">
                        <label htmlFor="bairro">Bairro:</label>
                        <input className="input-outline" ref={bairroRef} type="text" name="bairro" id="bairro" defaultValue={user?.Bairro} />
                    </div>
                    <div className="buttons">
                        <button className="btn" type="reset" onClick={e => { e.preventDefault(); resetForm() }}><FontAwesomeIcon icon={faTimes} /> Limpar Campos</button>
                        <button className="btn btn-primary" type="submit"><FontAwesomeIcon icon={faSearch} /> Procurar</button>
                    </div>
                </form>

                <div className="veterinarios">
                    {loading ? <PulseLoader color="#65AAFF" /> : htmlData.length > 0 ? htmlData : <span>Não encontramos nada nesta região</span>}
                </div>
            </main>
            <Footer />
        </>
    )
}
