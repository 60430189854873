import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import ConvitesTable from '../../components/ConvitesTable'
import api from '../../services/api'
import { ClipLoader, PulseLoader } from 'react-spinners'
import Modal from '../../components/Modal'
import config from '../../config.json'
import LoaderContainer from '../../components/LoaderContainer'
import toastr from 'toastr'
import Footer from '../../components/Footer'

export default function Convites() {

    const [convites, setConvites] = useState([])
    const [loading, setLoading] = useState(false)
    const [conviteLoading, setConviteLoading] = useState(false)
    const [deletarLoading, setDeletarLoading] = useState(false)

    const [modal, setModal] = useState({visible: false})
    const [deletarModal, setDeletarModal] = useState({visible: false})

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await api.get('convite')
                if (res.data && Array.isArray(res.data)) {
                    setConvites(res.data)
                } else {
                    setConvites([])
                }
            } catch (error) {
                console.error(error);
                toastr.error('Ocorreu um erro inesperado.')
            }
            setLoading(false)
        })()
    }, [])

    const deletarConvite = async (convite) => {
        setDeletarLoading(true)
        try {
            const {data} = await api.delete(`?id=${encodeURIComponent(convite.id)}`, {data: null, headers: {
                Accept: 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded'
            }})

            if (!data) {setDeletarLoading(false); setDeletarModal({visible: false}); return toastr.error("Ocorreu um erro inesperado.");}

            if (data.message) {
                toastr.success(data.message)
                setConvites([...convites.filter(currentConvite => currentConvite.id !== convite.id)])
            } else if (data.erro) {
                toastr.error(data.erro)
            }

        } catch (error) {
            console.error(error)
            toastr.error("Ocorreu um erro inesperado.")
        }
        setDeletarModal({visible: false})
        setDeletarLoading(false)
    }

    const confirmarDeletarConvite = async (convite) => {
        return setDeletarModal({
            title: `Você tem certeza que deseja remover o ${convite.CodConvidado ? 'veterinário' : 'convite'}?`,
            desc: 'Você não poderá reverter esta ação!',
            convite
        });
    }

    const criarConvite = async (tipo, acao = 0, CodConvidado) => {
        try {
            setConviteLoading(true)

            const requestData = {tipo, acao}

            if (acao !== 0) requestData.CodConvidado = CodConvidado

            const { data } = await api.post('convite', requestData)

            if (data && typeof data === 'object') {
                const tipoNome = tipo === 0 ? 'veterinário' : 'criador'
                switch (acao) {
                    case 0:
                        setConvites([data, ...convites])

                        setModal({
                            visible: true,
                            title: `Convite para ${tipoNome} criado com sucesso!`,
                            desc: 'Este convite só poderá ser usado uma vez e irá expirar em 24 horas',
                            type: 'copy',
                            value: `https://${config.dominioConvite}/${tipoNome.replace('á', 'a')}/?id=${encodeURIComponent(data.id)}`
                        })
                        break;
                    case 1:
                        setModal({
                            visible: true,
                            title: `Convite para editar o veterinario criado com sucesso!`,
                            desc: 'Este convite só poderá ser usado uma vez e irá expirar em 24 horas',
                            type: 'copy',
                            value: `https://${config.dominioConvite}/veterinario/editar?id=${encodeURIComponent(data.id)}`
                        })
                        break
                    case 2:
                        setModal({
                            visible: true,
                            title: `Convite para deletar o veterinario criado com sucesso!`,
                            desc: 'Este convite só poderá ser usado uma vez e irá expirar em 24 horas',
                            type: 'copy',
                            value: `https://${config.dominioConvite}/veterinario/deletar?id=${encodeURIComponent(data.id)}`
                        })
                        break
                    default:
                        break;
                }
             }

            setConviteLoading(false)
        } catch (error) {
            console.error(error)
            toastr.error("Ocorreu um erro inesperado ao criar o convite.")
        }
    }

    return (
        <>
            <Header>
                <span>Seus convites para Criadores e Veterinários</span>
                <div className="actions">
                    <button className="btn btn-light-primary" onClick={e => criarConvite(1)} disabled={conviteLoading}>{conviteLoading && <LoaderContainer upfront><ClipLoader size="12px" color="#65AAFF"/></LoaderContainer>} Convidar Criador</button>
                    <button className="btn btn-light-primary" onClick={e => criarConvite(0)} disabled={conviteLoading}>{conviteLoading && <LoaderContainer upfront><ClipLoader size="12px" color="#65AAFF"/></LoaderContainer>} Convidar Veterinário</button>
                </div>
            </Header>
            <main>
                <div className="card">
                    {loading ? <div className="d-flex-column flex-center"><PulseLoader color="#65AAFF"/></div> : <ConvitesTable criarConvite={criarConvite} deletarConvite={confirmarDeletarConvite} data={convites}/>}
                </div>
            </main>
            <Modal {...deletarModal} >
                <div style={{display: 'flex', marginTop: '20px', maxWidth: '240px', justifyContent: 'space-between'}}>
                    <button className="btn btn-primary" onClick={() => deletarConvite(deletarModal.convite)} disabled={deletarLoading}>{deletarLoading && <LoaderContainer upfront><ClipLoader size="12px" color="#65AAFF"/></LoaderContainer>} Sim, continuar</button>
                    <button className="btn" onClick={() => setDeletarModal({visible: false})} disabled={deletarLoading}>{deletarLoading && <LoaderContainer upfront><ClipLoader size="12px" color="#65AAFF"/></LoaderContainer>} Cancelar</button>
                </div>
            </Modal>
            <Modal {...modal} onClose={() => setModal({visible: false})} />
            <Footer/>
        </>
    )
}
