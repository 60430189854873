import React, { useLayoutEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import { Form } from '@unform/web';
import Input from '../../components/Input';
import useAuth from '../../contexts/useAuth';
import Select from '../../components/Select';
import './index.scss';
import api from '../../services/api';
import toastr from 'toastr';
import { ClipLoader, PulseLoader } from 'react-spinners';
import Footer from '../../components/Footer';
import InputMask from '../../components/InputMask';
import { validURL } from '../../util';

export default function MeusDados() {

    const formRef = useRef(null)

    const { user, setUser, loadingUser } = useAuth()

    const [loading, setLoading] = useState(false)

    const [isCNPJ, setIsCNPJ] = useState(user?.TipoPessoa === 'J')

    const [telefoneMask, setTelefoneMask] = useState('(99) 9999-99999')

    async function onSubmit(formData) {
        setLoading(true)
        formRef.current.setErrors({});
        try {
            const clearData = {
                ...formData,
                CEP: formData.CEP.replace(/(\.|-)/g, ''),
                CPF_CNPJ: formData.CPF_CNPJ.replace(/(\.|-|\/)/g, ''),
                Telefone: formData.Telefone.replace(/(\s|-|\(|\))/g, ''),
            }

            const erros = {}

            if (clearData.CPF_CNPJ.length < 11 || clearData.CPF_CNPJ.length > 14) {
                erros.CPF_CNPJ = clearData.TipoPessoa === 'F' ? 'CPF inválido.' : 'CNPJ inválido.'
            }

            if (clearData.CEP.length < 8) {
                erros.CEP = 'CEP inválido.'
            }

            if (clearData.Telefone.length > 1 && clearData.Telefone.length < 10) {
                erros.Telefone = 'Número inválido.'
            }

            if (clearData.DestinoURL && !validURL(clearData.DestinoURL)) {
                erros.DestinoURL = 'Site inválido.'
            }

            if (Object.keys(erros).length > 0) {setLoading(false); return formRef.current.setErrors(erros);}

            const {data} = await api.post('criador/editar', clearData);

            if (data) {
                if (data.formError) {setLoading(false); return formRef.current.setErrors(data.formError);}
                if (data.erro) {setLoading(false); return toastr.error(data.erro);}
                if (data.Instagram) formData.InstagramID = data.Instagram.CodRede;
                if (data.Facebook) formData.FacebookID = data.Facebook.CodRede;
            }

            toastr.success('Dados alterados com sucesso.')
            setUser({ ...user, ...formData })
        } catch (error) {
            console.error(error)
            toastr.error('Ocorreu um erro inesperado.')
        }

        setLoading(false)
    }

    function setTelefoneMaskByNumber(telefone) {
        if (!telefone) return setTelefoneMask('(99) 9999-99999');

        setTelefoneMask(telefone.replace(/(\s|\(|\)|-)/g, '').length <= 10 ? '(99) 9999-99999' : '(99) 9 9999-9999')
    }

    useLayoutEffect(() => {
        setIsCNPJ(user?.TipoPessoa === 'J')
        setTelefoneMaskByNumber(user?.Telefone)
    }, [user])

    return (
        <>
            <Header />
            <main className="meus-dados">
                <div className="card">
                    {!loadingUser ?
                        <Form onSubmit={onSubmit} initialData={user} ref={formRef}>
                            <div className="title">Informações básicas</div>
                            <div className="input-group">
                                <label htmlFor="Nome">Nome</label>
                                <Input name="Nome" required type="text" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Proprietario">Proprietário</label>
                                <Input name="Proprietario" required type="text" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Email">E-mail</label>
                                <Input name="Email" required type="email" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="TipoPessoa">Tipo de pessoa</label>
                                <Select name="TipoPessoa" options={[{ value: 'F', label: 'Fisica' }, { value: 'J', label: 'Jurídica' }]} onChange={e => {setIsCNPJ(e.target.value === 'J'); formRef.current.setFieldError('CPF_CNPJ', '')}} />
                            </div>
                            <div className="input-group">
                                <label htmlFor="CPF_CNPJ">{isCNPJ ? 'CNPJ' : 'CPF'}</label>
                                <InputMask name="CPF_CNPJ" required type="text" maskChar={null} mask={isCNPJ ? '99.999.999/9999-99' : '999.999.999-99'} />
                            </div>
                            <div className="title">Localização</div>
                            <div className="input-group">
                                <label htmlFor="CEP">CEP</label>
                                <InputMask name="CEP" type="text" maskChar={null} mask="99.999-999" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Estado">Estado</label>
                                <Select name="Estado" options={[{ "value": "", "label": "Selecione um estado" }, { "value": "AC", "label": "Acre" },{ "value": "AL","label": "Alagoas" },{ "value": "AP", "label": "Amapá" },{ "value": "AM", "label": "Amazonas" },{ "value": "BA", "label": "Bahia" },{ "value": "CE", "label": "Ceará" },{ "value": "DF", "label": "Distrito Federal" },{ "value": "ES", "label": "Espírito Santo" },{ "value": "GO", "label": "Goiás" },{ "value": "MA", "label": "Maranhão" },{ "value": "MT", "label": "Mato Grosso" },{ "value": "MS", "label": "Mato Grosso do Sul" },{ "value": "MG", "label": "Minas Gerais" },{ "value": "PA", "label": "Pará" },{ "value": "PB", "label": "Paraíba" },{ "value": "PR", "label": "Paraná" },{ "value": "PE", "label": "Pernambuco" },{ "value": "PI", "label": "Piauí" },{ "value": "RJ", "label": "Rio de Janeiro" },{ "value": "RN", "label": "Rio Grande do Norte" },{ "value": "RS", "label": "Rio Grande do Sul" },{ "value": "RO", "label": "Rondônia" },{ "value": "RR", "label": "Roraima" },{ "value": "SC", "label": "Santa Catarina" },{ "value": "SP", "label": "São Paulo" },{ "value": "SE", "label": "Sergipe" },{ "value": "TO", "label": "Tocantins" }]} />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Cidade">Cidade</label>
                                <Input name="Cidade" type="text" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Bairro">Bairro</label>
                                <Input name="Bairro" type="text" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Endereco">Endereço</label>
                                <Input name="Endereco" type="text" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Numero">Numero</label>
                                <Input name="Numero" type="text" />
                            </div>
                            <div className="title">Contato</div>
                            <div className="input-group">
                                <label htmlFor="Telefone">Telefone</label>
                                <InputMask name="Telefone" required type="text" mask={telefoneMask} maskChar={null} onChange={e => setTelefoneMaskByNumber(e.target.value)}/>
                            </div>
                            <div className="title">Social</div>
                            <div className="input-group">
                                <label htmlFor="DestinoURL">Site</label>
                                <Input name="DestinoURL" type="text" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Instagram">Instagram</label>
                                <Input name="Instagram" type="text" />
                                <Input name="InstagramID" hidden type="number" />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Facebook">Facebook</label>
                                <Input name="Facebook" type="text" />
                                <Input name="FacebookID" hidden type="number" />
                            </div>
                            <button type="submit" className="btn btn-primary btn-rounded" disabled={loading}>{loading ? <ClipLoader size="12px" color="white"/> : "Salvar"}</button>
                        </Form>
                        :
                        <div className="d-flex flex-center"><PulseLoader color="#65AAFF" /></div>
                    }
                </div>
            </main>
            <Footer/>
        </>
    )
}
