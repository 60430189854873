import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCopy, faEdit } from '@fortawesome/free-solid-svg-icons'
import copy from 'copy-to-clipboard'
import toastr from 'toastr'
import config from '../config.json'

export default function ConvitesTable({data, criarConvite, deletarConvite}) {
    const copiarConvite = (id, tipo) => {
        copy(`https://${config.dominioConvite}/${tipo === 1 ? 'criador' : 'veterinario'}?id=${encodeURIComponent(id)}`)
        toastr.success('Convite copiado!')
    }

    const htmlData = data.map(row => {
        return (
            <tr key={row.id}>
                <td>{row.CodConvidado ? row.Nome : 'Ninguém'}</td>
                <td>{row.Tipo === 1 ? 'Criador' : 'Veterinário'}</td>
                <td>{new Date(row.CriadoEm).toLocaleString('pt-br',{day: '2-digit', month: '2-digit', year: 'numeric'})}</td>
                <td>
                    {
                        row.CodConvidado ?
                            (row.Tipo === 0 && <button className="btn btn-light-primary" style={{width: '125px'}} onClick={() => criarConvite(row.Tipo, 1, row.CodConvidado)}><FontAwesomeIcon icon={faEdit} />Editar</button>)
                            :
                            <button className="btn btn-primary" onClick={() => copiarConvite(row.id, row.Tipo)}><FontAwesomeIcon icon={faCopy} />Copiar Link</button>
                    }
                    {
                        ( row.Tipo === 0 || !(row.Tipo === 1 && row.CodConvidado) ) &&
                            <button className="btn btn-outline-danger" onClick={() => deletarConvite(row)}><FontAwesomeIcon icon={faTrashAlt} />Deletar</button>
                    }
                </td>
            </tr>
        )
    })

    return (
        <>
            {htmlData.length > 0 &&
                <div className="responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{minWidth: '120px'}}>Usado por</th>
                                <th style={{width: '110px'}}>Tipo</th>
                                <th style={{width: '120px'}}>Criado em</th>
                                <th style={{width: '255px'}}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {htmlData}
                        </tbody>
                    </table>
                </div>
            }
            {htmlData.length < 1 && <div className="d-flex-column flex-center my-20px text-muted">Nenhum convite encontrado</div>}
        </>
    )
}

