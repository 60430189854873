import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../services/api'
import { reloadOnFail } from '../util'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const history = useHistory()
    const [user, setUser] = useState(null)

    const [loadingUser, setLoadingUser] = useState(true)

    async function login(email, senha) {
        setLoadingUser(true)
        try {
            const req = await api.post('login', { email, senha })
            setUser(req.data)
            setLoadingUser(false)
            return req
        } catch (error) {
            setLoadingUser(false)
            console.error(error)
            return error;
        }
    }

    const logout = useCallback(async function () {
        try {
            const req = await api.get('logout')
            document.cookie = ''
            history.push('/login');
            if (user) setUser(null);
            return req
        } catch (error) {
            // console.error(error)
            return error
        }
    }, [history, user])

    const loginOnUrlChange = useCallback(
        async () => {
            setLoadingUser(true)
            try {
                const { status, data } = await api.get('login')
                if (reloadOnFail() && status === 204) return logout();

                if (user) {
                    // Recarrega a página caso tenha trocado de usuário em outra aba
                    if (user.CodParceiro !== data.CodParceiro) {
                        history.go(0)
                    }
                } else {
                    setUser(data)
                }
            } catch (error) {
                console.error(error)
                if (user) setUser(null);
                if (reloadOnFail()) logout()
            }
            setLoadingUser(false)
        },
        [history, logout, user]
    )

    const [historyUnregister, setHistoryUnregister] = useState(false);

    const historyListen = useCallback(() => {
        if (user) return loginOnUrlChange();
    }, [user, loginOnUrlChange])

    useEffect(() => {
        (async () => {
            if (!user) {
                await loginOnUrlChange();
            }

            if (!historyUnregister) {
                history.listen(historyListen);
                setHistoryUnregister(true)
            }
        })()
    }, [loginOnUrlChange, history, user, historyUnregister, historyListen])

    return (
        <AuthContext.Provider
            value={
                { login, logout, user, setUser, loadingUser }
            }
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}

export default useAuth
