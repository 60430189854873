import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import useAuth from '../contexts/useAuth';

export default function Header({children}) {
    const style = {}

    const {user} = useAuth()

    if (children) {
        style.borderBottom = "1.4px solid rgba(0, 0, 0, .1)"
    } else {
        style.boxShadow = '0 3px 6px rgba(0, 0, 0, .1)';
    }

    const {logout} = useAuth();

    const [showNavList, setShowNavList] = useState(false)

    const closeNavList = () => setShowNavList(false);
    const openNavList = () => setShowNavList(true);

    return (
        <div className="header">
            <header style={style}>
                <div className="logo">
                    <img draggable="false" src="./SistemaPET_logo.png" alt="SistemaPET" />
                </div>
                {
                    user ? <>
                        <nav className="default-nav">
                            <NavLink exact draggable="false" className="nav-link" activeClassName="btn-nav-active" to="/">Veterinários</NavLink>
                            <NavLink draggable="false" className="nav-link" activeClassName="btn-nav-active" to="/dados">Meus dados</NavLink>
                            <NavLink draggable="false" className="nav-link" activeClassName="btn-nav-active" to="/convites">Convites</NavLink>
                        </nav>
                        <div className={`list-nav ${showNavList ? 'show-list-nav' : ''}`}>
                            <nav>
                                <button  onClick={closeNavList} className="btn close-list-nav" ><FontAwesomeIcon icon={faTimes}/></button>
                                <NavLink onClick={closeNavList} exact draggable="false" className="nav-link" activeClassName="btn-nav-active" to="/">Veterinários</NavLink>
                                <NavLink onClick={closeNavList} draggable="false" className="nav-link" activeClassName="btn-nav-active" to="/dados">Meus dados</NavLink>
                                <NavLink onClick={closeNavList} draggable="false" className="nav-link" activeClassName="btn-nav-active" to="/convites">Convites</NavLink>
                            </nav>
                        </div>
                    </>
                    :
                    <>
                        <nav className="default-nav">
                            <NavLink exact draggable="false" className="nav-link" activeClassName="btn-nav-active" to="/">Veterinários</NavLink>
                        </nav>
                        <div className={`list-nav ${showNavList ? 'show-list-nav' : ''}`}>
                            <nav>
                                <button  onClick={closeNavList} className="btn close-list-nav" ><FontAwesomeIcon icon={faTimes}/></button>
                                <NavLink onClick={closeNavList} exact draggable="false" className="nav-link" activeClassName="btn-nav-active" to="/">Veterinários</NavLink>
                            </nav>
                        </div>
                    </>
                }
                <div className="ml-auto"></div>
                {
                    user ?
                        <>
                            <div className=" mr-15px roboto-light hello" style={{textAlign: 'right', maxWidth: '230px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                <span className="text-muted">Olá, </span>
                                <span>{user ? user.Nome : ''}</span>
                            </div>
                            <button onClick={e => logout()} className="btn btn-outline-danger">Sair</button>
                            <button onClick={openNavList} className="btn open-list-nav"><FontAwesomeIcon icon={faBars}/></button>
                        </>
                    :
                        <>
                            <NavLink className="btn btn-outline-primary" to="/login">Entrar</NavLink>
                            <button onClick={openNavList} className="btn open-list-nav"><FontAwesomeIcon icon={faBars}/></button>
                        </>
                }
            </header>
            {children &&
                <div className="sub-header">
                    {children}
                </div>
            }
        </div>
    )
}
