import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function Input({ name, className, hidden, ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error, clearError } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return <div className={hidden ? 'd-none' : 'input-container'}>
        <input onFocus={() => clearError()} className={`input ${error ? 'input-error' : ''} ${className || ''}`.trim()} hidden={hidden} ref={inputRef} id={fieldName} name={fieldName} defaultValue={defaultValue} {...rest} />
        {error && <span className="error">{error}</span>}
    </div>;
}
