import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { AuthProvider } from '../contexts/useAuth'
import EsqueceuSenha from './auth/esqueceu-senha'
import Login from './auth/login'
import Convites from './convites'
import MeusDados from './meus-dados'
import QuemSomos from './quem-somos'
import Veterinarios from './veterinarios'

export default function Router() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Switch>
                    <Route path="/" exact component={Veterinarios}/>
                    <Route path="/dados" exact component={MeusDados}/>
                    <Route path="/convites" exact component={Convites}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/quem-somos" exact component={QuemSomos}/>
                    <Route path="/esqueci-senha" exact component={EsqueceuSenha}/>
                </Switch>
            </AuthProvider>
        </BrowserRouter>
    )
}
