import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';


const Select = ({ name, options, className, children, ...rest }) => {
    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error, clearError } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <div className="input-container">
            <select onFocus={() => clearError()} className={`input ${error ? 'input-error' : ''} ${className || ''}`.trim()} ref={selectRef} id={fieldName} name={fieldName} defaultValue={defaultValue} {...rest}>
                {
                    options ? options.map(option => (<option key={option.value} value={option.value}>{option.label}</option>)) : children
                }
            </select>
            {error && <span className="error">{error}</span>}
        </div>
    )
};

export default Select;
