import { faFacebookSquare, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import politicaAnimalLogo from '../assets/politicaanimal.png';

import useAuth from '../contexts/useAuth'
import Modal from './Modal';

export default function Footer() {
    const [introModal, setIntroModal] = useState(false)

    const {user} = useAuth()

    return (
        <footer>
            <Modal className="intro-modal" visible={introModal}>
                <iframe style={{width: "100%"}} title="Tutorial" height="315" src="https://www.youtube.com/embed/zzLq1FAbDks" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <button className="btn btn-primary float-right fullwidth-mobile" onClick={() => {
                    setIntroModal(false)
                }}>Fechar</button>
            </Modal>
            <div className="social">
                <a href="https://facebook.com/sistemapet" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare}/></a>
                <a href="https://instagram.com/sistemapet" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram}/></a>
                <a href="https://youtube.com/sistemapet" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube}/></a>
            </div>
            <div className="apoio d-flex flex-center">
                <span className="mr-5px text-muted">Apoio</span>
                <a href="https://www.instagram.com/politicaanimal/" title="Instagram Política Animal" rel="noreferrer" target="_blank">
                    <img style={{height: '50px'}} src={politicaAnimalLogo} alt="Politica Animal"/>
                </a>
            </div>
            <div className="copyright">
                {user && <button className="btn" style={{marginRight: '10px', background: 'none'}} onClick={() => setIntroModal(true)}><FontAwesomeIcon icon={faPlay}/></button>}
                <NavLink className="quem-somos" to="/quem-somos">Quem Somos?</NavLink>
                <div style={{display: 'inline-block', wordWrap: 'unset'}}>
                    <span className="text-muted">{new Date().getFullYear()} ©</span>
                    <a href="https://sistemapet.com" target="_blank" rel="noopener noreferrer">SistemaPET</a>
                </div>
            </div>
        </footer>
    )
}
