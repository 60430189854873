import config from "../config.json"
import Axios from "axios";
import { reloadOnFail } from "../util";

const api = Axios.create({
    baseURL: config.apiURL,
    timeout: 3 * 60 * 1000,
    withCredentials: true
})

api.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.message.match(/\d+/)[0] === "401") {
        if (reloadOnFail()) {
            return window.location.pathname = "/login"
        }
    }

    return Promise.reject(error)
})

export default api
