import { faFacebookSquare, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextClamp from 'react-string-clamp'
import React from 'react'
import { faArrowRight, faPhone } from '@fortawesome/free-solid-svg-icons';
import { formatPhone, formatURL, validURL } from '../util';

export default function VetCard({vet, ...rest}) {

    if (!vet) return <></>;
    return (
        <div className="vet-card d-flex-column" {...rest}>
            <div className="d-flex">
                {vet.Logo && <img className="centered-logo" src={vet.Logo} alt="" />}
                <div className="d-flex-column vet-info">
                    <span>{vet.Nome}<FontAwesomeIcon className="float-right vet-mobile" icon={faArrowRight}/></span>
                    {vet.Criador && <span className="text-muted">Indicado por: {vet.Criador}</span>}
                    <span className="roboto-light"><TextClamp title={vet.Descricao} text={vet.Descricao} lines="3" /></span>
                </div>
                <div className="vet-desktop vet-social">
                    {vet.Facebook && (<a target="_blank" rel="noreferrer" href={validURL(vet.Facebook) ? formatURL(vet.Facebook) : `https://facebook.com/${vet.Facebook}`} title="Facebook"><FontAwesomeIcon icon={faFacebookSquare} /></a>)}
                    {vet.Instagram && (<a target="_blank" rel="noreferrer" href={validURL(vet.Instagram) ? formatURL(vet.Instagram) : `https://instagram.com/${vet.Instagram.replace('@', '')}`} title="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>)}
                </div>
            </div>

            <div className="d-flex text-muted vet-desktop mt-20px">
                <div className="d-flex-column left">
                    <div className="d-flex-column phones">
                        {vet.Telefone && <span><FontAwesomeIcon icon={faPhone}/> {formatPhone(vet.Telefone)}</span>}
                        {vet.Whatsapp && <a target="_blank" rel="noreferrer" title="Ir para o WhatsApp." href={`https://api.whatsapp.com/send?phone=55${vet.Whatsapp}`}><span><FontAwesomeIcon icon={faWhatsapp}/> {formatPhone(vet.Whatsapp)}</span></a>}
                    </div>
                    {vet.Site && <a title="Ir para o Site." target="_blank" rel="noreferrer" href={formatURL(vet.Site, false)}>{vet.Site}</a>}
                </div>
                <div className="d-flex-column right">
                    <span>{vet.Cidade}</span>
                    <span>{vet.Bairro}</span>
                    <span>{vet.Logradouro}, {vet.Numero}</span>
                    <a title="Abrir no Google Maps." target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/${encodeURIComponent(`${vet.Cidade} ${vet.Estado} - ${vet.Logradouro}, ${vet.Numero}`)}`}>Abrir no Google Maps</a>
                </div>
            </div>
        </div>
    )
}
