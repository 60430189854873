export const reloadOnFail = function () {
    const ignorePaths = ['esqueci-senha', 'quem-somos', 'login', '']

    for (const path of ignorePaths) {
        if (window.location.pathname === `/${path}`) return false;
    }

    return true;
}

export function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export function formatPhone(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    if (cleaned.length <= 10) {
        const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/)
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`
        }
    } else {
        const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/)
        if (match) {
            return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`
        }
    }

    return null
}

export function formatURL(url, defaultHttps = true) {
    if (!url.match(/^http/g)) return `${defaultHttps ? 'https://' : 'http://'}${url}`;

    return url;
}
