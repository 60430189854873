import { recaptcha } from '../../config.json'
import React, { useRef, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import toastr from 'toastr'
import api from '../../services/api'
import Recaptcha from 'react-recaptcha'

export default function EsqueceuSenha() {
    const emailRef = useRef()
    const [loading, setLoading] = useState()
    const history = useHistory()

    const onSubmit = async (e) => {
        e.preventDefault()
        const recaptchaValue = window.grecaptcha.getResponse();

        if (!recaptchaValue) return toastr.error('Por favor, confirme o captcha.');

        setLoading(true)
        try {
            const {data} = await api.post('criador/esqueci-senha', {
                Email: emailRef.current?.value,
                Recaptcha: recaptchaValue
            });

            if (data && data.erro) {
                toastr.error(data.erro)
                window.grecaptcha.reset()
            } else {
                toastr.success('Enviamos um email com as informações da sua conta.')
                history.push('/login')
            }
        } catch (error) {
            toastr.error("Ocorreu um erro inesperado.")
        }
        setLoading(false)
    }

    return (
        <div className="d-flex-column flex-center login">
            <div className="brand">
                <img src="SistemaPET_logo.png" alt="SistemaPET" />
                <h1>Veterinários</h1>
            </div>
            <div className="desc">
                <h2>Esqueceu a senha?</h2>
                <span>Insira seu email registrado</span>
            </div>
            <form className="d-flex-column flex-center" onSubmit={onSubmit}>
                <input required className="input" type="email" placeholder="E-mail" id="email" autoFocus={true} autoComplete="email" ref={emailRef} />
                <Recaptcha
                    hl="pt-BR"
                    sitekey={recaptcha}
                />
                <div className="buttons" style={{marginTop: '40px'}}>
                    <NavLink to='login' className="flex-center btn btn-outline-secondary btn-rounded" style={{margin: '0'}}>Voltar</NavLink>
                    <button className="flex-center btn btn-primary btn-rounded" type="submit" disabled={loading}>{loading ? <ClipLoader size="12px" color="white"/> : "Enviar"}</button>
                </div>
            </form>
        </div>
    )
}
