import React, { useRef, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';

import { useField } from '@unform/core';

const InputMask = ({ name, className, ...rest }) => {
    const inputRef = useRef(null);
    const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);

    return (
        <div className="input-container">
            <ReactInputMask onFocus={() => clearError()} className={`input ${error ? 'input-error' : ''} ${className || ''}`.trim()} ref={inputRef} name={fieldName} id={fieldName} defaultValue={defaultValue} {...rest} />
            {error && <span className="error">{error}</span>}
        </div>
    );
};

export default InputMask;
